<template>
  <v-container
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
    fluid
  >
    
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/areas" />
        </v-col>
      </v-row>
    </v-card-actions>
    <areas-campos
      :carregando-salvar="carregandoSalvar"
      :validacao-formulario="validacaoFormulario"
      salvarDisabled
      :area="area"
      :readonly="readonly"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import areas from "@/api/areas";
import _ from "lodash";

export default {
  components: {
    AreasCampos: () => import("./AreasCampos"),
  },

  data() {
    return {
      carregandoSalvar: false,
     
      readonly: true,
      validacaoFormulario: {},
      area: {},
      tab: null,
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await areas.buscar(this.$route.params.id);
        var area = {
          nome: resposta.data.data.nome,
          id: resposta.data.data.id,
          ativo: resposta.data.data.ativo,
        };

        var endereco = {
          "endereco.id": null,
        };

        if (!_.isEmpty(resposta.data.data.enderecos[0])) {
          endereco = resposta.data.data.enderecos[0];
          endereco["endereco_id"] = endereco.id;
        }

        area.endereco = endereco;
        this.area = area;
      } catch (e) {
        this.$router.push("/areas", () =>
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(area) {
      this.carregandoSalvar = true;

      try {
        await areas.atualizar(area.id, area);
        this.$router.push("/areas", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = e.response.data.erros;
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        return;
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
